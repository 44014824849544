import { Switch } from '@headlessui/react';
import axios from 'axios';
import classNames from 'classnames';
import { useState } from 'react';
import { encode } from './CareersApplication';

interface IContactForm {
  firstName: string;
  lastName: string;
  company: string;
  email: string;
  phone: string;
  message: string;
  newsletter: boolean;
}

export default function Contact({ navHeight }: any) {
  const [form, setForm] = useState<IContactForm>({
    firstName: '',
    lastName: '',
    company: '',
    email: '',
    phone: '',
    message: '',
    newsletter: false,
  });
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  return (
    <div
      id='contact'
      style={{ scrollMarginTop: `${navHeight}px` }}
      className='snap-start bg-white rounded-xl max-w-7xl xl:mx-auto mx-4 sm:mx-8 shadow-lg lg:z-20 py-16 px-4 sm:px-6 lg:py-4'
    >
      <div className='relative max-w-xl lg:max-w-none mx-auto lg:mx-4 lg:flex lg:flex-row grid grid-cols-1 sm:grid-cols-2 gap-x-8 lg:bg-white rounded-xl'>
        <svg
          className='lg:hidden absolute left-full transform translate-x-1/2'
          width={404}
          height={404}
          fill='none'
          viewBox='0 0 404 404'
          aria-hidden='true'
        >
          <defs>
            <pattern
              id='85737c0e-0916-41d7-917f-596dc7edfa27'
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits='userSpaceOnUse'
            >
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className='text-gray-200'
                fill='currentColor'
              />
            </pattern>
          </defs>
          <rect
            width={404}
            height={404}
            fill='url(#85737c0e-0916-41d7-917f-596dc7edfa27)'
          />
        </svg>
        <svg
          className='lg:hidden absolute right-full bottom-0 transform -translate-x-1/2'
          width={404}
          height={404}
          fill='none'
          viewBox='0 0 404 404'
          aria-hidden='true'
        >
          <defs>
            <pattern
              id='85737c0e-0916-41d7-917f-596dc7edfa27'
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits='userSpaceOnUse'
            >
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className='text-gray-200'
                fill='currentColor'
              />
            </pattern>
          </defs>
          <rect
            width={404}
            height={404}
            fill='url(#85737c0e-0916-41d7-917f-596dc7edfa27)'
          />
        </svg>
        <div className='text-center flex flex-col justify-around col-span-1 sm:col-span-2 lg:text-left lg:col-span-1'>
          <h2 className=' text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl'>
            Schedule service or Request quote
          </h2>
          <p className='mt-4 text-lg leading-6 text-gray-500'>
            Call or text{' '}
            <a className='text-red-600' href='tel:4055201894'>
              (405) 520-1894
            </a>
            , or fill out the form to schedule
          </p>
          <div className='my-4 text-sm italic text-center underline underline-offset-4 select-none text-red-600'>
            By clicking submit you agree to receive phone calls and emails regarding this
            form.
          </div>
        </div>
        <div className='mt-12 col-span-1 sm:col-span-2 lg:mt-0 lg:col-span-4'>
          <form
            name='contact-us'
            className='grid grid-cols-1 gap-y-6 sm:grid-cols-2 lg:grid-cols-4 xl:grid-cols-6 sm:gap-x-8'
            onSubmit={(e) => {
              setSubmitting(true);
              e.preventDefault();
              axios
                .post('/', encode({ 'form-name': 'contact-us', ...form }), {
                  headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                })
                .then((res) => {
                  if (res.status === 200) {
                    setSubmitted(true);
                  }
                })
                .catch((err) => {})
                .finally(() => {
                  setSubmitting(false);
                });
            }}
          >
            <div>
              <label
                htmlFor='firstName'
                className='block text-sm font-medium text-gray-700'
              >
                First name*
              </label>
              <div className='mt-1'>
                <input
                  required
                  type='text'
                  name='firstName'
                  id='firstName'
                  value={form.firstName}
                  onChange={(e) => {
                    setForm({ ...form, firstName: e.target.value });
                  }}
                  autoComplete='given-name'
                  className='py-1 px-2 block w-full shadow-sm focus:ring-sky-500 focus:border-sky-500 border-gray-300 rounded-md'
                />
              </div>
            </div>
            <div>
              <label
                htmlFor='last-name'
                className='block text-sm font-medium text-gray-700'
              >
                Last name*
              </label>
              <div className='mt-1'>
                <input
                  type='text'
                  name='last-name'
                  id='last-name'
                  value={form.lastName}
                  onChange={(e) => {
                    setForm({ ...form, lastName: e.target.value });
                  }}
                  autoComplete='family-name'
                  required
                  className='py-1 px-2 block w-full shadow-sm focus:ring-sky-500 focus:border-sky-500 border-gray-300 rounded-md'
                />
              </div>
            </div>
            <div className='sm:col-span-2'>
              <label
                htmlFor='company'
                className='block text-sm font-medium text-gray-700'
              >
                Company
              </label>
              <div className='mt-1'>
                <input
                  type='text'
                  name='company'
                  id='company'
                  value={form.company}
                  onChange={(e) => {
                    setForm({ ...form, company: e.target.value });
                  }}
                  autoComplete='organization'
                  placeholder='optional'
                  className='py-1 px-2 placeholder:text-slate-400 block w-full shadow-sm focus:ring-sky-500 focus:border-sky-500 border-gray-300 rounded-md'
                />
              </div>
            </div>
            <div className='sm:col-span-2'>
              <label htmlFor='email' className='block text-sm font-medium text-gray-700'>
                Email*
              </label>
              <div className='mt-1'>
                <input
                  id='email'
                  name='email'
                  type='email'
                  value={form.email}
                  onChange={(e) => {
                    setForm({ ...form, email: e.target.value });
                  }}
                  autoComplete='email'
                  required
                  className='py-1 px-2 block w-full shadow-sm focus:ring-sky-500 focus:border-sky-500 border-gray-300 rounded-md'
                />
              </div>
            </div>
            <div className='sm:col-span-2'>
              <label htmlFor='phone' className='block text-sm font-medium text-gray-700'>
                Phone Number*
              </label>
              <div className='mt-1 relative rounded-md shadow-sm'>
                <input
                  type='text'
                  name='phone'
                  required
                  id='phone'
                  value={form.phone}
                  onChange={(e) => {
                    setForm({ ...form, phone: e.target.value });
                  }}
                  autoComplete='tel'
                  className='placeholder:text-slate-400 py-1 px-2 block w-full focus:ring-sky-500 focus:border-sky-500 border-gray-300 rounded-md'
                  placeholder='(555) 123-4567'
                />
              </div>
            </div>
            <div className='sm:col-span-2'>
              <label
                htmlFor='message'
                className='block text-sm font-medium text-gray-700'
              >
                Message
              </label>
              <div className='mt-1'>
                <textarea
                  id='message'
                  name='message'
                  rows={3}
                  value={form.message}
                  placeholder='optional'
                  onChange={(e) => {
                    setForm({ ...form, message: e.target.value });
                  }}
                  className='py-1 placeholder:text-slate-400 px-2 block w-full shadow-sm focus:ring-sky-500 focus:border-sky-500 border border-gray-300 rounded-md'
                />
              </div>
            </div>
            <div className='sm:col-span-2'>
              <div className='flex items-start'>
                <div className='flex-shrink-0'>
                  <Switch
                    name='newsletter'
                    id='newsletter'
                    checked={form.newsletter}
                    onChange={(checked) => setForm({ ...form, newsletter: checked })}
                    className={classNames(
                      form.newsletter ? 'bg-sky-600' : 'bg-gray-200',
                      'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500'
                    )}
                  >
                    <span className='sr-only'>Sign up for newsletter.</span>
                    <span
                      aria-hidden='true'
                      className={classNames(
                        form.newsletter ? 'translate-x-5' : 'translate-x-0',
                        'inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                      )}
                    />
                  </Switch>
                </div>
                <div className='ml-3'>
                  <p className='text-base text-gray-500'>
                    Sign up for email updates, promotions, sales, and more.
                  </p>
                </div>
              </div>

              <button
                type='submit'
                className='hidden w-full mt-4 lg:inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500'
              >
                {submitting ? (
                  <>Submitting...</>
                ) : submitted ? (
                  <>We'll contact you soon!</>
                ) : (
                  <>Submit</>
                )}
              </button>
            </div>
            <div className='lg:hidden sm:col-span-2 text-sm italic text-center underline underline-offset-4 select-none text-red-600'>
              By clicking submit you agree to receive phone calls and emails regarding
              this form.
            </div>
            <div className='sm:col-span-2 lg:hidden'>
              <button
                type='submit'
                className='w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500'
              >
                {submitting ? (
                  <>Submitting...</>
                ) : submitted ? (
                  <>We'll contact you soon!</>
                ) : (
                  <>Submit</>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
