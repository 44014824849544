import { PhoneIcon } from '@heroicons/react/solid';
import PromoBanner from './PromoBanner';
import HeroImage from './HeroImage';
import Navbar from './Navbar';

export default function Hero({ navigation, useRef, navHeight }: any) {
  return (
    <div className='w-screen'>
      <PromoBanner navHeight={navHeight} />
      <Navbar navigation={navigation} useRef={useRef} />
      <div
        id='top'
        className='snap-start transition-none relative lg:bg-sky-600 overflow-hidden w-full shadow-md lg:shadow-none mb-4'
      >
        <div className='max-w-7xl mx-auto h-full'>
          <div
            style={{
              paddingTop: `${navHeight}px`,
            }}
            className=' transition-none relative text-white h-full z-10 pb-8 bg-sky-600 lg:bg-sky-600 sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full  lg:pb-28 xl:pb-32'
          >
            <svg
              className='hidden lg:block absolute right-0 inset-y-0 h-full w-48 transform translate-x-1/2'
              fill='currentColor'
              viewBox='0 0 100 100'
              preserveAspectRatio='none'
              aria-hidden='true'
            >
              <polygon points='50,0 100,0 50,100 0,100' />
            </svg>

            <main className='z-10 mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-12 lg:px-8'>
              <div className='sm:text-center lg:text-left'>
                <h1 className='text-4xl tracking-tight font-extrabold text-slate-900 sm:text-5xl md:text-6xl '>
                  <span className='text-white w-full lg:inline-flex first-letter:absolute'>
                    Acock
                  </span>{' '}
                  <span className='block text-slate-800 xl:inline'>
                    <span className='text-white'>Climate Control</span>
                  </span>
                </h1>
                <div className='mt-3 text-base text-white sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0'>
                  <div>
                    <div className='text-white inline '>Trusted</div> climate control at
                    your <div className='text-white inline '>convenience.</div>
                  </div>
                  <div className='pt-2'>
                    <div className='text-white red-border-font inline font-extrabold'>
                      Residential
                    </div>{' '}
                    and{' '}
                    <div className='text-white sky-border-font inline font-extrabold'>
                      commercial
                    </div>{' '}
                    HVAC services for Edmond, Oklahoma City, and Shawnee areas.
                  </div>
                </div>
                <div className='mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start'>
                  <div className='mt-3 sm:mt-0 sm:ml-3'>
                    <a
                      href='tel:4055201894'
                      className='h-full w-full justify-center flex items-center px-6 py-3 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-red-600 md:py-4 md:text-lg md:px-6  hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500'
                    >
                      <PhoneIcon className='-ml-2 mr-2 h-6 w-6' aria-hidden='true' />
                      Call now
                    </a>
                  </div>
                  <div className='mt-3 sm:mt-0 sm:ml-3'>
                    <a
                      href='#services'
                      className='w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-sky-600 bg-white hover:bg-slate-100 md:py-4 md:text-lg md:px-10'
                    >
                      Services
                    </a>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
        <div className='pointer-events-none hide-scroll lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 overflow-x-auto'>
          <HeroImage />
        </div>
      </div>
    </div>
  );
}
