import { useEffect, useRef, useState } from "react";
import PromoBanner from "./PromoBanner";
import Navbar from "./Navbar";
import Footer from "./Footer";
import PrivacyPolicy from "./PrivacyPolicy";

const navigation = [
    { name: 'Home', href: '#top' },
    { name: 'Contact', href: '#contact' },
    { name: 'Services', href: '#services' },
    { name: 'Reviews', href: '#reviews' },
    { name: 'Careers', href: '#careers' },
    { name: 'Privacy', href: '/privacy'}
  ];
  
  const social = [
    {
      name: 'Facebook',
      href: 'https://www.facebook.com/AcockClimateControl/',
      icon: (props: any) => (
        <svg fill='currentColor' viewBox='0 0 24 24' {...props}>
          <path
            fillRule='evenodd'
            d='M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z'
            clipRule='evenodd'
          />
        </svg>
      ),
    },
  ];
  
export default function Privacy() {
    const [navHeight, setNavHeight] = useState(0);
    const navRef = useRef<any>(null);

    useEffect(() => {
        if (!navRef || !navRef.current) return;
        setNavHeight(navRef.current.clientHeight);
    }, [navRef.current?.clientHeight])
    return (
        <>
            <div className="w-screen">
                <PromoBanner navHeight={navHeight} />
                <Navbar navigation={navigation} useRef={navRef} />
            </div>
            <PrivacyPolicy />
            <Footer navigation={navigation} social={social} />
        </>
    )
}