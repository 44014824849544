import { Popover, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { PhoneIcon } from "@heroicons/react/solid";
import { Fragment, useState } from "react";
import TextBrand from "./TextBrand";
import Rooster from "./Rooster";
// import YelpLogo from "./YelpLogo";

export default function Navbar({ navigation, useRef }: any) {
	const [open, setOpen] = useState(false);
	return (
		<div className='w-screen'>
			<Popover>
				<div
					className='bg-white z-20 pt-12 lg:pt-10  fixed top-0 w-full shadow-lg'
					ref={useRef}
				>
					<nav
						className='relative flex align-middle justify-between h-10 pb-12 lg:pb-10 px-6 lg:px-8 '
						aria-label='Global'
					>
						<div className='flex flex-grow flex-shrink-0 lg:flex-grow-0'>
							<div className='flex items-center flex-row justify-between w-full'>
								<a href='#top'>
									<span className='sr-only'>Acock Brand</span>
									<div className='flex flex-row items-center'>
										<Rooster />

										<div className='hidden sm:grid place-items-center font-bold'>
											<TextBrand />
										</div>
									</div>
								</a>
								<div className='-mr-2 flex items-center lg:hidden'>
									<a
										href='#contact'
										style={{ height: "52px" }}
										className='mr-4 font-medium bg-sky-600 text-white shadow-md border-sky-700 border-2 rounded-lg p-2 inline-flex items-center justify-center hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500'
									>
										Schedule Service
									</a>
									<a
										href='tel:4055201894'
										style={{ height: "52px" }}
										className='mr-2 bg-red-600 text-white shadow-md border-red-700 border-2 rounded-lg p-2 inline-flex items-center justify-center hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500'
									>
										<PhoneIcon
											className='h-8 w-8'
											aria-label='Call'
										/>
									</a>
									<Popover.Button
										onClick={() => setOpen(true)}
										style={{ height: "52px" }}
										className='ml-2 bg-white shadow-md border-slate-200 border-2 rounded-lg p-2 inline-flex items-center justify-center text-slate-400  hover:bg-slate-100 focus:outline-none focus:ring-2 focus:ring-sky-500'
									>
										<span className='sr-only'>
											Open main menu
										</span>
										<MenuIcon
											className='h-8 w-8'
											aria-hidden='true'
										/>
									</Popover.Button>
								</div>
							</div>
						</div>
						<a
							href='tel:4055201894'
							className='hidden xl:inline-flex place-items-center px-4 text-black font-semibold text-2xl'
						>
							Call{" "}
							<div className='px-2 text-red-600'>
								(405) 520-1894
							</div>{" "}
							now!
						</a>
						<a
							href='tel:4055201894'
							className='hidden lgxl:inline-flex xl:hidden place-items-center px-4 text-red-600 font-semibold text-2xl'
						>
							<div className='px-2 font-normal'>
								(405) 520-1894
							</div>
						</a>
						<div className='hidden text-2xl lg:inline-flex place-items-center lg:px-4 lg:space-x-8'>
							{navigation.map((item: any) => (
								<a
									key={item.name}
									href={item.href}
									className='font-medium text-slate-500 hover:text-slate-900'
								>
									{item.name === "Contact"
										? "Schedule"
										: item.name}
								</a>
							))}
						</div>
					</nav>
					<div className='bg-red-600 text-white font-bold text-center'>
						Free estimates!
					</div>
				</div>

				{open && (
					<div>
						<Transition
							as={Fragment}
							enter='duration-150 ease-out'
							enterFrom='opacity-0 scale-95'
							enterTo='opacity-100 scale-100'
							leave='duration-100 ease-in'
							leaveFrom='opacity-100 scale-100'
							leaveTo='opacity-0 scale-95'
						>
							<Popover.Panel
								static
								focus
								className='fixed z-50 top-0 inset-x-0 p-2 transition transform origin-top-right lg:hidden'
							>
								<div className='rounded-lg shadow-lg bg-white ring-1 ring-black ring-opacity-5 overflow-hidden'>
									<div className='px-5 pt-4 flex items-center justify-between'>
										<a href='#top' className='inline-flex'>
											<TextBrand className='z-50 ' />
										</a>
										<div className='-mr-2'>
											<Popover.Button
												onClick={() => setOpen(false)}
												className='bg-white rounded-md p-2 inline-flex items-center justify-center text-slate-400 hover:text-slate-500 hover:bg-slate-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-sky-500'
											>
												<span className='sr-only'>
													Close main menu
												</span>
												<XIcon
													className='h-8 w-8'
													aria-hidden='true'
												/>
											</Popover.Button>
										</div>
									</div>
									<div className='px-2 pt-2 pb-3 space-y-1'>
										{navigation.map((item: any) => (
											<a
												onClick={() => setOpen(false)}
												key={item.name}
												href={item.href}
												className='block px-3 py-2 rounded-md text-base font-medium text-slate-700 hover:text-slate-900 hover:bg-slate-50'
											>
												{item.name}
											</a>
										))}
										{/* <hr />
										<a
											onClick={() => setOpen(false)}
											className="block px-3 py-2 rounded-md text-base font-medium text-slate-700 hover:text-slate-900 hover:bg-slate-50"
											href="https://www.yelp.com/biz/acock-climate-control-llc-shawnee"
											target="_blank"
											rel="noreferrer"
										>
											<YelpLogo hasPreText={true} />
										</a> */}
									</div>
									<div className='block w-full px-5 py-2 text-center font-medium bg-red-600 text-red-600'></div>
								</div>
							</Popover.Panel>
						</Transition>
					</div>
				)}
			</Popover>
		</div>
	);
}
