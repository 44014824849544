import axios from 'axios';
import { useRef, useState } from 'react';

interface IApplyForm {
  applyFirstName: string;
  applyLastName: string;
  applyJobTitle: string;
  applyEmail: string;
  applyPhone: string;
  applyMessage: string;
  applyResume: any;
}

export const encode = (data: any) => {
  const formData = new FormData();
  Object.keys(data).forEach((k) => {
    formData.append(k, data[k]);
  });
  return formData;
};

export default function CareersApplication({ show }: any) {
  const fileInputRef = useRef<any>(null);
  const [form, setForm] = useState<IApplyForm>({
    applyFirstName: '',
    applyLastName: '',
    applyJobTitle: '',
    applyEmail: '',
    applyPhone: '',
    applyMessage: '',
    applyResume: '',
  });
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  return (
    <form
      encType='multipart/form-data'
      name='apply'
      id='apply'
      className='grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8'
      onSubmit={(e) => {
        setSubmitting(true);
        e.preventDefault();
        axios
          .post('/', encode({ 'form-name': 'apply', ...form }))
          .then((res) => {
            if (res.status === 200) {
              setSubmitted(true);
            }
          })
          .catch((err) => {})
          .finally(() => {
            setSubmitting(false);
          });
      }}
    >
      <div>
        <label htmlFor='applyFirstName' className='block text-sm font-medium text-white'>
          First name
        </label>
        <div className='mt-1'>
          <input
            required
            type='text'
            name='applyFirstName'
            id='applyFirstName'
            autoComplete='given-name'
            className='py-3 px-4 block w-full shadow-sm focus:ring-red-500 focus:border-red-500 border-gray-300 rounded-md'
            value={form.applyFirstName}
            onChange={(e) => setForm({ ...form, applyFirstName: e.target.value })}
          />
        </div>
      </div>
      <div>
        <label htmlFor='applyLastName' className='block text-sm font-medium text-white'>
          Last name
        </label>
        <div className='mt-1'>
          <input
            required
            type='text'
            name='applyLastName'
            id='applyLastName'
            autoComplete='family-name'
            value={form.applyLastName}
            onChange={(e) => setForm({ ...form, applyLastName: e.target.value })}
            className='py-3 px-4 block w-full shadow-sm focus:ring-red-500 focus:border-red-500 border-gray-300 rounded-md'
          />
        </div>
      </div>
      <div className='sm:col-span-2'>
        <label htmlFor='applyJobTitle' className='block text-sm font-medium text-white'>
          Job Title
        </label>
        <div className='mt-1'>
          <input
            type='text'
            name='applyJobTitle'
            id='applyJobTitle'
            value={form.applyJobTitle}
            onChange={(e) => setForm({ ...form, applyJobTitle: e.target.value })}
            required
            className='py-3 px-4 block w-full shadow-sm focus:ring-red-500 focus:border-red-500 border-gray-300 rounded-md'
          />
        </div>
      </div>
      <div className='sm:col-span-2'>
        <label htmlFor='applyEmail' className='block text-sm font-medium text-white'>
          Email
        </label>
        <div className='mt-1'>
          <input
            id='applyEmail'
            name='applyEmail'
            type='email'
            value={form.applyEmail}
            onChange={(e) => setForm({ ...form, applyEmail: e.target.value })}
            autoComplete='email'
            required
            className='py-3 px-4 block w-full shadow-sm focus:ring-red-500 focus:border-red-500 border-gray-300 rounded-md'
          />
        </div>
      </div>
      <div className='sm:col-span-2'>
        <label htmlFor='applyPhone' className='block text-sm font-medium text-white'>
          Phone Number
        </label>
        <div className='mt-1 relative rounded-md shadow-sm'>
          <input
            type='text'
            name='applyPhone'
            id='applyPhone'
            autoComplete='tel'
            value={form.applyPhone}
            onChange={(e) => setForm({ ...form, applyPhone: e.target.value })}
            className='placeholder:text-slate-400 py-3 px-4 block w-full focus:ring-red-500 focus:border-red-500 border-gray-300 rounded-md'
            placeholder='(555) 123-4567'
            required
          />
        </div>
      </div>
      <div className='sm:col-span-2'>
        <label htmlFor='applyMessage' className='block text-sm font-medium text-white'>
          Message
        </label>
        <div className='mt-1'>
          <textarea
            id='applyMessage'
            name='applyMessage'
            rows={4}
            value={form.applyMessage}
            onChange={(e) => setForm({ ...form, applyMessage: e.target.value })}
            className='py-3 px-4 block w-full shadow-sm focus:ring-red-500 focus:border-red-500 border border-gray-300 rounded-md'
          />
        </div>
      </div>
      <div className='sm:col-span-2 grid place-items-center'>
        <label
          htmlFor='applyResume'
          className='w-full flex flex-col items-center justify-center border-4 border-gray-300 border-dashed rounded h-36 px-6 text-lg text-gray-600 focus:outline-none focus:ring focus:border-blue-300 cursor-pointer'
        >
          <svg
            className='w-8 h-8 text-white'
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            stroke='currentColor'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='2'
              d='M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12'
            />
          </svg>
          <span className='mt-2 text-base leading-normal text-white font-bold'>
            {form.applyResume ? <>{form.applyResume.name}</> : <>Upload resume.</>}
          </span>
          <input
            required
            type='file'
            id='applyResume'
            name='applyResume'
            onChange={(e) => {
              if (!fileInputRef || !fileInputRef.current) return;
              setForm({ ...form, applyResume: fileInputRef.current.files.item(0) });
            }}
            className='hidden'
            ref={fileInputRef}
          />
        </label>
        <p className='py-2 text-gray-200'>File must be a PDF, RTF, or DOCX. </p>
      </div>
      <div className='sm:col-span-2'>
        <button
          type='submit'
          className='w-full inline-flex items-center justify-center px-6 py-3 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500'
          disabled={submitting}
        >
          {submitting ? (
            <>Submitting...</>
          ) : submitted ? (
            <>We'll contact you soon!</>
          ) : (
            <>Submit</>
          )}
        </button>
      </div>
    </form>
  );
}
