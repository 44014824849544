import { Link } from "react-router-dom";

export default function PrivacyPolicy() {
    const company = "Acock Climate Control LLC";
    const website = "https://acockhvac.com/"
    return (
        <div className="bg-white px-6 py-32 lg:px-8">
            <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
                <p className="text-base font-semibold leading-7 text-indigo-600">Legal Notice</p>
                <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Privacy Policy of {company}</h1>
                <p className="mt-6 text-xl leading-8">
                Effective Date: 5/9/2024
                </p>
                <div className="mt-10 max-w-2xl">
                <h2 className="text-2xl font-bold tracking-tight text-gray-900">1. Introduction</h2>
                <p className="mt-6">
                    This Privacy Policy describes how {company} (the &quot;Company&quot;, &quot;we&quot;, &quot;us&quot;, or &quot;our&quot;) collects, uses, and shares personal information of users of this website and our employment application process. By using our website or applying for a job, you agree to the collection and use of information in accordance with this policy.
                </p>

                <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">2. Information Collection and Use</h2>
                <p className="mt-6">
                    We collect several different types of information for various purposes to provide and improve our service to you.
                </p>
                <ul className="mt-8 space-y-8 text-gray-600">
                    <li className="flex gap-x-3">
                    <span className="mt-1 h-5 w-5 flex-none text-indigo-600">●</span>
                    <span>Personal Data: While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you (&quot;Personal Data&quot;).</span>
                    </li>
                    <li className="flex gap-x-3">
                    <span className="mt-1 h-5 w-5 flex-none text-indigo-600">●</span>
                    <span>Usage Data: We may also collect information how the Service is accessed and used (&quot;Usage Data&quot;).</span>
                    </li>
                </ul>

                <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">3. Data Use</h2>
                <p className="mt-6">
                    {company} uses the collected data for various purposes:
                </p>
                <ul className="mt-8 space-y-8 text-gray-600">
                    <li className="flex gap-x-3">
                    <span className="mt-1 h-5 w-5 flex-none text-indigo-600">●</span>
                    <span>To provide and maintain our Service</span>
                    </li>
                    <li className="flex gap-x-3">
                    <span className="mt-1 h-5 w-5 flex-none text-indigo-600">●</span>
                    <span>To notify you about changes to our Service</span>
                    </li>
                    <li className="flex gap-x-3">
                    <span className="mt-1 h-5 w-5 flex-none text-indigo-600">●</span>
                    <span>To allow you to participate in interactive features of our Service when you choose to do so</span>
                    </li>
                    <li className="flex gap-x-3">
                    <span className="mt-1 h-5 w-5 flex-none text-indigo-600">●</span>
                    <span>To provide customer support</span>
                    </li>
                    <li className="flex gap-x-3">
                    <span className="mt-1 h-5 w-5 flex-none text-indigo-600">●</span>
                    <span>To gather analysis or valuable information so that we can improve our Service</span>
                    </li>
                    <li className="flex gap-x-3">
                    <span className="mt-1 h-5 w-5 flex-none text-indigo-600">●</span>
                    <span>To monitor the usage of our Service</span>
                    </li>
                    <li className="flex gap-x-3">
                    <span className="mt-1 h-5 w-5 flex-none text-indigo-600">●</span>
                    <span>To detect, prevent and address technical issues</span>
                    </li>
                </ul>

                <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">4. Data Sharing</h2>
                <p className="mt-6">
                    Your information, including Personal Data, may be transferred to — and maintained on — computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ from those from your jurisdiction.
                </p>
                <p className="mt-6">
                    If you are located outside the United States and choose to provide information to us, please note that we transfer the data, including Personal Data, to the United States and process it there.
                </p>
                <p className="mt-6">
                    Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.
                </p>
                <p className="mt-6">
                    {company} will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of your data and other personal information.
                </p>

                <h2 className="mt-16 text-2xl font-bold tracking-tight text-gray-900">5. Contact Us</h2>
                <p className="mt-6">
                    For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us through our website at <Link className="text-indigo-600" to="/">{website}</Link>.
                </p>
                </div>
            </div>
        </div>
    )
}
