import weather from "../resources/weather.svg";

export default function HeroImage() {
	return (
		<div className='lg:h-full w-screen lg:relative lg:pt-16 xl:pt-28'>
			<div className='photobanner w-screen h-full'>
				<div className='flex flex-row bg-white lg:scale-[200%] h-full w-full xl:w-full'>
					<img
						//   className='lg:absolute bg-white transition-none object-cover lg:h-full sm:h-72 md:h-96'
						className='bg-white lg:w-1/2 lg:translate-x-4'
						src={weather}
						alt='Hero'
					/>
					<img
						//   className='bg-white hidden lg:inline-flex translate-x-full pl-2 object-cover transition-none lg:h-full sm:h-72 md:h-96'
						className='hidden lg:inline-flex bg-white w-1/2'
						src={weather}
						alt='Hero'
					/>
				</div>
			</div>
		</div>
	);
}
