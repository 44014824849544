import { SpeakerphoneIcon, XIcon } from "@heroicons/react/outline";
import classNames from "classnames";
import { useEffect, useState } from "react";

export default function PromoBanner({ navHeight }: any) {
	const [open, setOpen] = useState(false);
	useEffect(() => {
		setOpen(true);
	}, []);
	return (
		<div
			style={{ marginTop: `calc(${navHeight}px + 10px)` }}
			className={classNames(
				open
					? "opacity-95 translate-y-0"
					: "-translate-y-[100%] -z-50 opacity-0",
				"transition-all fixed z-50  top-0 inset-x-0 pb-2 sm:pb-5"
			)}
		>
			<div className='max-w-5xl mx-auto px-2 sm:px-6 lg:px-8'>
				<div className='p-2 rounded-lg bg-red-600 shadow-lg sm:p-3'>
					<div className='flex items-center justify-between flex-wrap'>
						<div className='w-0 flex-1 flex items-center'>
							<span className='flex p-2 rounded-lg bg-red-800'>
								<SpeakerphoneIcon
									className='h-6 w-6 text-white'
									aria-hidden='true'
								/>
							</span>
							<p className='ml-1 sm:ml-3 font-medium text-white truncate'>
								<span className='md:hidden'>
									$139 yearly maintenance!
								</span>
								<span className='hidden md:inline'>
									Yearly preventative maintenance for only
									$139 per unit!
								</span>
							</p>
						</div>
						<div className='hidden order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto'>
							<a
								href='#top'
								className='flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-red-600 bg-white hover:bg-red-50'
							>
								Learn more
							</a>
						</div>
						<div className='order-2 flex flex-row flex-shrink-0 sm:order-3 sm:ml-2'>
							<a
								onClick={() => setOpen(false)}
								href='#contact'
								className='opacity-95 mr-1 flex p-2 font-medium rounded-md bg-white hover:bg-slate-200 focus:outline-none focus:ring-2 focus:ring-white'
							>
								Schedule
							</a>
							<button
								onClick={() => setOpen(false)}
								type='button'
								className='-mr-1 flex p-2 rounded-md hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-white'
							>
								<span className='sr-only'>Dismiss</span>
								<XIcon
									className='h-6 w-6 text-white'
									aria-hidden='true'
								/>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
