import TextBrand from "./TextBrand";

export default function Footer({ navigation, social }: any) {
	return (
		<footer className='bg-white'>
			<div className='max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8'>
				<nav
					className='-mx-5 -my-2 flex flex-wrap justify-center'
					aria-label='Footer'
				>
					{navigation.map((item: any) => (
						<div key={item.name} className='px-5 py-2'>
							<a
								href={item.href}
								className='font-bold text-base text-slate-500 hover:text-red-600'
							>
								{item.name}
							</a>
						</div>
					))}
					<div key='Facebook' className='px-5 py-2'>
						<a
							href='https://www.facebook.com/AcockClimateControl/'
							target='_blank'
							rel='noreferrer'
							className='font-bold text-base text-slate-500 hover:text-red-600'
						>
							Facebook
						</a>
					</div>
					{/* <div key='Yelp' className='px-5 py-2'>
						<a
							href='https://www.yelp.com/biz/acock-climate-control-llc-shawnee'
							target='_blank'
							rel='noreferrer'
							className='font-bold text-base text-slate-500 hover:text-red-600'
						>
							Yelp
						</a>
					</div> */}
				</nav>

				<a href='#top' className='grid place-items-center mt-12'>
					<TextBrand />
				</a>
				<p className='mt-12 text-center text-base text-slate-400'>
					&copy; 2024 <a href='https://tela.gg'>Tela.gg</a>. All
					rights reserved.
				</p>
			</div>
		</footer>
	);
}
