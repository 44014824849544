import { StarIcon } from '@heroicons/react/solid';
import classNames from 'classnames';

const reviews = [
  {
    id: 1,
    title: 'AC services',
    rating: 5,
    content: `
        <p>Great experience with Acock Climate Control. I was able to get service within 30 minutes!  Jennifer was great to help and the guys were professional and helpful.  My A/C is working great! I highly recommend Acock and will not hesitate to call them again! Thanks!</p>
      `,
    author: 'Mark Heard',
    avatarSrc: require('../resources/mark-heard.png'),
  },
  {
    id: 2,
    title: 'Heating system and AC installation',
    rating: 5,
    content: `
        <p>These guys did such a great job.  After contacting them on a Thursday, they showed up on the following Tuesday at 8:00 a.m. and got right to work.  They removed the existing blower, condenser, etc.   Installed the new system by about 5:30 or so that evening.  Very pleasant guys, hard workers, and professional.</p>
      `,
    author: 'Karis Reavis',
    avatarSrc: require('../resources/karis-reavis.png'),
  },
  {
    id: 3,
    title: 'Ductless heating and AC services',
    rating: 5,
    content: `
        <p>Incredible customer service and super fast response time. They really listen to what you need as a customer and do an excellent job taking care of your needs.</p>
      `,
    author: 'Heather Cahill',
    avatarSrc: require('../resources/heather-cahill.png'),
  },
];

export default function Reviews({ navHeight }: any) {
  return (
    <div
      id='reviews'
      style={{ scrollMarginTop: `${navHeight}px` }}
      className='bg-white snap-start'
    >
      <div className='max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8'>
        <h2 id='reviews-heading' className='sr-only'>
          Reviews
        </h2>

        <div className='space-y-10'>
          {reviews.map((review: any) => (
            <div key={review.id} className='flex flex-col sm:flex-row'>
              <div className='mt-6 order-2 sm:mt-0 sm:ml-16'>
                <h3 className='text-sm font-medium text-slate-900'>{review.title}</h3>
                <p className='sr-only'>{review.rating} out of 5 stars</p>

                <div
                  className='mt-3 space-y-6 text-sm text-slate-600'
                  dangerouslySetInnerHTML={{ __html: review.content }}
                />
              </div>

              <div className='order-1 flex items-center sm:flex-col sm:items-start'>
                <img
                  src={review.avatarSrc}
                  alt={`${review.author}.`}
                  className='h-12 w-12 rounded-full'
                  loading='lazy'
                />

                <div className='ml-4 sm:ml-0 sm:mt-4'>
                  <p className='text-sm font-medium text-slate-900'>{review.author}</p>
                  <div className='mt-2 flex items-center'>
                    {[0, 1, 2, 3, 4].map((rating) => (
                      <StarIcon
                        key={rating}
                        className={classNames(
                          review.rating > rating ? 'text-red-600' : 'text-slate-200',
                          'h-5 w-5 flex-shrink-0'
                        )}
                        aria-hidden='true'
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ))}
          {/* <a href='#top' className='text-red-600 font-medium'>
            <div className='mt-6'>
              See more
              <ChevronRightIcon className='w-3 h-3 inline' />
            </div>
          </a> */}
        </div>
      </div>
    </div>
  );
}
