import IconShippingSimple from './IconShippingSimple';
import IconWarrantySimple from './IconWarrantySimple';

const incentives = [
  {
    name: 'Fast and friendly service',
    icon: <IconShippingSimple />,
  },
  {
    name: 'HVAC certified technicians',
    icon: <IconWarrantySimple />,
  },
];

export default function Incentives({ navHeight }: any) {
  return (
    <div style={{ scrollMarginTop: `${navHeight}px` }} className='bg-white snap-start'>
      <div className='max-w-7xl mx-auto py-24 sm:px-2 sm:py-32 lg:px-4'>
        <div className='max-w-2xl mx-auto px-4 lg:max-w-none'>
          <div className='max-w-3xl'>
            <h2 className='text-4xl font-extrabold tracking-tight text-slate-900'>
              We built our business on customer service
            </h2>
            <p className='mt-4 text-slate-500'>
              We understand the importance of climate control during Oklahoma weather.
              That's why we believe it's important to have the right equipment and the
              right team for your unique application. Our certified technicians ensure we
              meet project requirements and our staff is here to answer any questions you
              might have.
            </p>
          </div>
          <div className='mt-16 grid grid-cols-2 gap-y-10 gap-x-8 lg:grid-cols-3'>
            {incentives.map((incentive) => (
              <div key={incentive.name} className='sm:flex lg:block'>
                <div className='sm:flex-shrink-0'>{incentive.icon}</div>
                <div className='mt-4 pt-4 sm:mt-0 sm:ml-6 lg:mt-6 lg:ml-0'>
                  <h3 className='text-sm font-medium text-slate-900'>{incentive.name}</h3>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
