export default function Features({ features, navHeight }: any) {
  return (
    <div
      id='services'
      style={{ scrollMarginTop: `${navHeight}px` }}
      className='bg-sky-600 shadow-lg snap-start'
    >
      <div className='max-w-4xl mx-auto px-4 py-16 sm:px-6 sm:pt-20 sm:pb-24 lg:max-w-7xl lg:pt-24 lg:px-8'>
        <h2 className='text-3xl font-extrabold text-white tracking-tight'>
          Commercial and residential services
        </h2>
        <p className='mt-4 max-w-3xl text-lg text-sky-200'>
          Acock Climate Control provides tailored installation, repairs, replacement, and
          maintenance for any customer or project size.
        </p>
        <div className='mt-12 grid grid-cols-2 gap-x-6 gap-y-12 sm:grid-cols-2 lg:mt-16 lg:grid-cols-4 lg:gap-x-8 lg:gap-y-16'>
          {features.map((feature: any) => (
            <div key={feature.name} className='flex flex-col'>
              <div className='mt-6 flex flex-col flex-grow'>
                <h3 className='text-lg font-medium text-white'>{feature.name}</h3>
                <div className='mt-2 text-base text-sky-100'>{feature.description}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
